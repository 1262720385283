export const SET_BACKGROUND_OPACITY = "SET_BACKGROUND_OPACITY";
export const FETCH_PROJECTS_REQUEST = "FETCH_PROJECTS_REQUEST";
export const FETCH_PROJECTS_SUCCESS = "FETCH_PROJECTS_SUCCESS";
export const FETCH_PROJECTS_FAILURE = "FETCH_PROJECTS_FAILURE";
export const SEND_MAIL_REQUEST = "SEND_MAIL_REQUEST";
export const SEND_MAIL_SUCCESS = "SEND_MAIL_SUCCESS";
export const SEND_MAIL_FAILURE = "SEND_MAIL_FAILURE";
export const RESET_MAIL_MODE = "RESET_MAIL_MODE";
export const SET_DRAWER_OPEN = "SET_DRAWER_OPEN";
export const SET_PROJECT_HOVERED = "SET_PROJECT_HOVERED";
export const SET_SELECTED_PROJECT = "SET_SELECTED_PROJECT";
export const SET_PROJECTS_MODAL_VISIBLE = "SET_PROJECTS_MODAL_VISIBLE";
export const SET_FILTER_MODE = "SET_FILTER_MODE";
export const SET_CONTACT_ME_MODAL_VISIBLE = "SET_CONTACT_ME_MODAL_VISIBLE";
export const SET_NAME = "SET_NAME";
export const SET_EMAIL = "SET_EMAIL";
export const SET_BODY = "SET_BODY";
export const SET_ERRORS = "SET_ERROES";
